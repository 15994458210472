<template>
  <div class="ss-tonnage">
    <div v-if="!isMobile" class="search-ton">
      <div class="ton-top">
        <el-card shadow="never" class="box-card">
          <el-form ref="emailtctform" :model="emailtctform" label-width="80px" :rules="rules" size="small">
            <div class="form-left">
<!--              <el-row>-->
<!--                <el-col :span="8">-->
<!--                  <el-form-item  :label="$t('message.email.mailtheme')" label-width="100px">-->
<!--                    <el-input v-model="emailtctform.subject" :placeholder="$t('message.email.mailtheme')" style="width: 100%;">-->
<!--                    </el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="14">-->
<!--                  <el-form-item :label="$t('message.email.sendtime')" label-width="100px">-->
<!--                    <el-col :span="10" >-->
<!--                      <el-form-item prop="send_time_before">-->
<!--                        <el-date-picker type="date"-->
<!--                                        :placeholder="$t('message.search.startdate')"-->
<!--                                        v-model="emailtctform.send_time_before"-->
<!--                                        style="width: 100%;"-->
<!--                        >-->
<!--                        </el-date-picker>-->
<!--                      </el-form-item>-->
<!--                    </el-col>-->
<!--                    <el-col class="line" :span="2">&#45;&#45;</el-col>-->
<!--                    <el-col :span="10" >-->
<!--                      <el-form-item prop="send_time_after">-->
<!--                        <el-date-picker type="date"-->
<!--                                        :placeholder="$t('message.search.enddate')"-->
<!--                                        v-model="emailtctform.send_time_after"-->
<!--                                        style="width: 100%;"-->
<!--                        >-->
<!--                        </el-date-picker>-->
<!--                      </el-form-item>-->
<!--                    </el-col>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->

<!--              </el-row>-->
<!--              <el-row>-->
<!--                <el-col :span="8">-->
<!--                  &lt;!&ndash;                                    <el-form-item  :label="$t('message.email.sender')"  label-width="100px">-->
<!--                                                          <el-autocomplete v-model="searchemailform.sendername" :placeholder="$t('message.email.sender')" style="width: 100%;">-->
<!--                                                          </el-autocomplete>-->
<!--                                                      </el-form-item>&ndash;&gt;-->
<!--                  <el-form-item :label="$t('message.email.sender')"-->
<!--                                prop="sendername" label-width="100px">-->
<!--                    <el-input v-model="emailtctform.sender"-->
<!--                              :placeholder="$t('message.email.sender')"-->
<!--                              style="width: 100%"-->
<!--                              @input="senderEmail($event)"-->
<!--                    >-->
<!--                    </el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="8">-->
<!--                  <el-form-item :label="$t('message.email.recipient')"-->
<!--                                prop="Recipient" label-width="100px">-->
<!--                    <el-input v-model="emailtctform.receiver"-->
<!--                              :placeholder="$t('message.email.recipient')"-->
<!--                              style="width: 100%"-->
<!--                              @input="recipient($event)"-->
<!--                    >-->
<!--                    </el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="4">-->
<!--                  <el-button style="margin-left: 5%" type="primary" size="small" @click="getTctEmail">{{$t('message.email.retrieval')}}</el-button>-->
<!--                  <el-button style="margin-left: 5%" type="primary" size="small" @click="resetForm">{{$t('message.reset')}}</el-button>-->
<!--                </el-col>-->
<!--              </el-row>-->
              <el-row>
                <el-col :span="9">
                  <el-form-item
                      :label="$t('message.tct.account')"
                      prop="account"
                  >
                    <el-input
                        v-model="emailtctform.account"
                        style="width: 100%"
                        :placeholder="$t('message.tct.enter_accountname')"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1"></el-col>
                <el-col :span="13">
                  <el-form-item :label="$t('message.tct.dwt')" prop="dwt">
                    <el-col :span="5">
                      <el-form-item  prop="dwtMin">
                        <el-input v-model.number="emailtctform.dwtMin" type="number"  :placeholder="$t('message.search.min')" @change="dwtDownchange" :min="0" oninput="if(value<0)value=0"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1">--</el-col>
                    <el-col :span="5">
                      <el-form-item  prop="dwtMax">
                        <el-input v-model.number="emailtctform.dwtMax" type="number" :placeholder="$t('message.search.max')" @change="dwtUpchange"  :min="0" oninput="if(value<0)value=0"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col id="col_2" :span="2">----</el-col>
                    <el-col :span="8">
                      <el-form-item prop="dwt_type">
                        <el-select
                            v-model="emailtctform.dwt_type"
                            :placeholder="$t('message.tct.please_select')"
                            @change="typechange"
                        >
                          <el-option
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item :label="$t('message.tct.delivery_area')">
                    <el-col :span="10">
                      <el-form-item prop="deliverySeaareaName">
                        <el-autocomplete
                            v-model="emailtctform.deliverySeaareaName"
                            :fetch-suggestions="querySearchDeliverySea"
                            :trigger-on-focus="false"
                            @select="selectdeliverysea"
                            :placeholder="$t('message.tct.sea_area')"
                            style="width: 100%"
                            @input="deliverySeaareaId($event)"
                            @change="changedeliverysea"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2">--</el-col>
                    <el-col :span="10">
                      <el-form-item prop="deliveryPortName">
                        <el-autocomplete
                            v-model="emailtctform.deliveryPortName"
                            :fetch-suggestions="querySearchDeliveryPort"
                            :trigger-on-focus="true"
                            @select="selectdeliveryport"
                            :placeholder="$t('message.tct.port')"
                            style="width: 100%"
                            @input="deliveryPortId($event)"
                            @change="reselectdeliveryport"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
                <el-col :span="14">
                  <el-form-item :label="$t('message.tct.laycan')" prop="laycandate">

                    <el-col :span="10">
                      <el-form-item prop="laycanStart">
                        <el-date-picker
                            type="date"
                            :placeholder="$t('message.search.startdate')"
                            v-model="emailtctform.laycanStart"
                            style="width: 100%"
                        >
                        </el-date-picker>
                      </el-form-item>
                    </el-col>

                    <el-col class="line" :span="2">--</el-col>

                    <el-col :span="10">
                      <el-form-item prop="laycanEnd">
                        <el-date-picker
                            type="date"
                            :placeholder="$t('message.search.enddate')"
                            v-model="emailtctform.laycanEnd"
                            style="width: 100%"
                        ></el-date-picker>
                      </el-form-item>
                    </el-col>

                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item :label="$t('message.tct.redelivery_area')">
                    <el-col :span="10">
                      <el-form-item prop="redeliverySeaareaName">
                        <el-autocomplete
                            v-model="emailtctform.redeliverySeaareaName"
                            :fetch-suggestions="querySearchRedeliverySea"
                            :trigger-on-focus="false"
                            @select="selectredeliverysea"
                            :placeholder="$t('message.tct.sea_area')"
                            style="width: 100%"
                            @input="redeliverySeaareaId($event)"
                            @change="changeredeliverysea"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="2">--</el-col>
                    <el-col :span="10">
                      <el-form-item prop="redeliveryPortName">
                        <el-autocomplete
                            v-model="emailtctform.redeliveryPortName"
                            :fetch-suggestions="querySearchRedeliveryPort"
                            :trigger-on-focus="true"
                            @select="selectredeliveryport"
                            :placeholder="$t('message.tct.port')"
                            style="width: 100%"
                            @input="redeliveryPortId($event)"
                            @change="reselectredeliveryport"
                        >
                        </el-autocomplete>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
                <el-col :span="14">
                  <el-form-item :label="$t('message.tct.duration')"  >
                    <el-col :span="6">
                      <el-form-item prop="durationDown" >
                        <el-input
                            type="number"
                            v-model.number="emailtctform.durationDown"
                            style="width: 100%"
                            :placeholder="$t('message.search.min')"
                            :min="0" oninput="if(value<0)value=0"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" :span="1">--</el-col>
                    <el-col :span="6">
                      <el-form-item prop="durationUp" >
                        <el-input
                            type="number"
                            v-model.number="emailtctform.durationUp"
                            style="width: 100%"
                            :placeholder="$t('message.search.max')"
                            :min="0" oninput="if(value<0)value=0"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="2"></el-col>
                    <el-col :span="6">
                      <el-select
                          v-model="emailtctform.durationType"
                          :placeholder="$t('message.tct.please_select')"
                      >
                        <el-option
                            v-for="item in options_date"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item
                      :label="$t('message.tct.send_time')"
                      prop="sendDays"
                  >
                    <el-input
                        v-model.number="emailtctform.sendDays"
                        type="number"
                        style="width: 118%"
                        :placeholder="$t('message.tct.please_issuetime')"
                        :min="0"
                        @input="sendDays"
                    >
                      <template #append>{{$t('message.withdays')}}</template>
                    </el-input
                    >
                  </el-form-item>
                </el-col>
                <el-col :span="2"> </el-col>
                <el-col :span="9">
                  <el-form-item>
                    <el-button
                        class="search_btn"
                        type="primary"
                        @click="getTctEmail()"
                    >{{ $t("message.tct.search") }}</el-button>
                    <!--51005	收藏的租船信息分页卡片查询-->
                    <el-button @click="resetForm('emailtctform')">{{
                        $t("message.tct.reset")
                      }}</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="form-right">
            </div>
          </el-form>
        </el-card>
      </div>
      <el-tabs class="result_list" v-model="activeName">
        <!--列表显示信息-->
        <!--tableData[index].id-->
        <el-tab-pane :label="$t('message.list')" name="table">
          <el-table
              #default="scope"
              :data="tableData" :border="false"
              style="width: 100%"
              @row-click="goDetails"
              :header-cell-style="{background:'#fff',color:'#515a6e','text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              :show-overflow-tooltip="true">
<!--            <el-table-column type="index" width="50" />-->
<!--            <el-table-column
                prop="subject"
                :label="$t('message.email.mailtheme')"
                min-width="70"
            >
            </el-table-column>
            <el-table-column
                prop="sender"
                :label="$t('message.email.sender')"
                min-width="120"
            >
            </el-table-column>
            <el-table-column
                prop="receiver"
                :label="$t('message.email.recipient')"
                min-width="120"
            >
            </el-table-column>
            <el-table-column
                :label="$t('message.email.sendtime')"
                min-width="100"
            >
              <template #default="scope">
                <span> {{editTime(scope.row.send_time, '{y}-{m}-{d} {h}:{i}:{s}')}}</span>
              </template>
            </el-table-column>
            <el-table-column
                :label="$t('message.handle')"
                min-width="120">
              <template #default="scope">
                <div class="actions">
                  <el-button
                      size="mini" type="success"
                      :disabled="detaildisabled"
                      @click.stop="open_Detail(scope.row)" plain>{{$t('message.detail')}}
                  </el-button>
                  <el-button
                      size="mini"
                      :disabled="editdisabled"
                      @click.stop="open_Edit(scope.row)">{{$t('message.edit')}}
                  </el-button>
                  <el-button
                      size="mini" type="danger"
                      :disabled="deletedisabled"
                      @click.stop="open_Del(scope.row.id)">{{$t('message.delete')}}
                  </el-button>
                </div>
              </template>
            </el-table-column>-->
            <el-table-column
                prop="comment.account"
                :label="$t('message.tct.account')"
                min-width="60">
              <!-- 后端没有这个字段 -->
              <template  #default="scope">
                <span>{{scope.row.account}}</span>
              </template>

            </el-table-column>
            <el-table-column
                prop="comment.dwtMax"
                :label="$t('message.tct.dwt')"
                min-width="60">
              <template  #default="scope">
                <span>{{scope.row.dwt_min}}-{{scope.row.dwt_max}}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="comment.deliverySeaareaCname"
                :label="$t('message.tct.delivery_area')"
                min-width="80">
              <template  #default="scope">
                <span>{{scope.row.delivery_seaarea}},{{ scope.row.delivery_port}}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="comment.redeliverySeaareaCname"
                :label="$t('message.tct.redelivery_area')"
                min-width="80">
              <template  #default="scope">
                <span>{{scope.row.redelivery_seaarea}},{{scope.row.redelivery_port}}</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="comment.laycanStart"
                :label="$t('message.tct.laycan')"
                min-width="80">
              <template #default="scope">
                <i class="el-icon-time"></i>
                <span style="margin-left: 5px">{{scope.row.laycan_start}} ~ {{scope.row.laycan_end}}</span>
              </template>
            </el-table-column>
<!--            <el-table-column-->
<!--                :label="$t('message.email.sendtime')"-->
<!--                min-width="100"-->
<!--            >-->
<!--              <template #default="scope">-->
<!--                <span> {{editTime(scope.row.send_time, '{y}-{m}-{d} {h}:{i}:{s}')}}</span>-->
<!--              </template>-->
<!--            </el-table-column>-->
            <el-table-column
              :label="$t('message.tonnage.createdTime')"
              :sortable="true"
              prop="create_time"
              min-width="80"
              :sort-method="SortTimeMethod"
            >
            <template #default="scope">
              {{getCreateTime(scope.row.create_time)}}
            </template>
            </el-table-column>
            <el-table-column
                :label="$t('message.handle')"
                min-width="120">
<!--              :disabled="detaildisabled"-->
              <template #default="scope">
                <div class="actions">
                  <el-button
                      size="mini" type="success"
                      @click.stop="goDetails(scope.row)" plain>{{$t('message.detail')}}
                  </el-button>
                  <el-button
                      size="mini"
                      :disabled="editdisabled"
                      @click.stop="go_edit(scope.row)">{{$t('message.edit')}}
                  </el-button>
                  <el-button
                      size="mini" type="danger"
                      :disabled="deletedisabled"
                      @click.stop="open_Del(scope.row.id)">{{$t('message.delete')}}
                  </el-button>
                </div>
              </template>
            </el-table-column>
            
          </el-table>
        </el-tab-pane>
      </el-tabs>
<!--      <div  class="list-footer">-->
<!--        <ul style="list-style: none">-->
<!--          <li><el-button size="small" @click="prePage">{{$t('message.email.lastpage')}}</el-button></li>-->
<!--          <li style="font-size:17px">{{pagination.current}}</li>-->
<!--          <li><el-button size="small" @click="nextPage">{{$t('message.email.nextpage')}}</el-button></li>-->
<!--          <li style="font-size:17px">共{{Math.ceil(pagination.total / 10)}}页</li>-->
<!--        </ul>-->
<!--      </div>-->
      <div  class="list-footer">
        <el-pagination
            small
            :prev-text="$t('message.previous')"
            :next-text="$t('message.next')"
            layout="slot,prev, pager, next"
            background
            v-model:currentPage="currentPage1"
            :total="Math.ceil(pagination.total/2)"
            :page-size="pagination.pagesize"
            @current-change="currentChange"
        />
      </div>
      <el-dialog
          :title="$t('message.email.deleteinformation')"
          v-model="del_dialog"
          width="30%"
          :before-close="handleClose">
        <span>{{$t('message.email.sentence3')}}</span>
        <template #footer>
                <span class="dialog-footer">
                <el-button @click="del_dialog = false">{{$t('message.email.cancel')}}</el-button>
                <el-button type="primary" @click="go_del(delObj.emaildetail_id)">{{$t('message.email.ok')}}</el-button>
                </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  deletetctemail, tctemailList, tctemailcard, updateemailbyid, tonnageemailpage, tctemailpage,
} from "../../api/emailextract";
import axios from "axios";
import {getToken} from "../../utils/auth";
import {parseTime} from '../../utils/index'
import {getseaarea} from "../../api/associativeinput";
export default {
  data() {
    const validatelaycanEnd = (rule, value, callback) => {
      if(this.emailtctform.laycanStart>this.emailtctform.laycanEnd){
        callback(new Error(window.vm.$t('message.enddatevalide')))
      }
      else{
        callback()
      }
    };
    const validatelaycanStart = (rule, value, callback) => {
      if(this.emailtctform.laycanEnd!=='' && this.emailtctform.laycanStart>this.emailtctform.laycanEnd){
        callback(new Error(window.vm.$t('message.startdatevalide')))
      }
      else{
        callback()
      }
    };
    const validatedwtMax = (rule, value, callback) => {
      if (parseInt(this.emailtctform.dwtMin)>parseInt(this.emailtctform.dwtMax) ||
          parseFloat(this.emailtctform.dwtMin)>parseFloat(this.emailtctform.dwtMax)){
        callback(new Error(window.vm.$t('message.maxmessage')))
      }
      else {
        callback()
      }
    };
    const validatedwtMin = (rule, value, callback) => {
      if(this.emailtctform.dwtMax!==''){
        if (parseInt(this.emailtctform.dwtMin)>parseInt(this.emailtctform.dwtMax) ||
            parseFloat(this.emailtctform.dwtMin)>parseFloat(this.emailtctform.dwtMax)){
          callback(new Error(window.vm.$t('message.minmessage')))
        }
        else {
          callback()
        }}
    };
    const validatedutationUp = (rule, value, callback) => {
      if(this.emailtctform.durationDown>this.emailtctform.durationUp){
        callback(new Error(window.vm.$t('message.enddatevalide')))
      }
      else{
        callback()
      }
    };
    const validatedutationDown = (rule, value, callback) => {
      if(this.emailtctform.durationUp!==''){
        if(this.emailtctform.durationDown>this.emailtctform.durationUp){
          callback(new Error(window.vm.$t('message.startdatevalide')))
        }
        else{
          callback()
        }}
    };

    return {
      editdisabled:'',
      deletedisabled:'',
      detaildisabled:'',
      emailtctform:{
        type:'tct',
        creator_id: localStorage.getItem('customerId'),
        account:'',
        accountId:'',
        dwtMin: '',
        dwtMax: '',
        dwt_type: '',
        laycanStart: '',
        laycanEnd:'',
        durationUp: '',
        durationDown:'',
        durationType:'',
        deliverySeaareaId: '',
        deliverySeaareaName:'',
        deliveryPortId: '',
        deliveryPortName: '',
        redeliverySeaareaId: '',
        redeliverySeaareaName:'',
        redeliveryPortId:'',
        redeliveryPortName:'',
        sendDays:'',
        languageCode:''
     //   receiver:'',
      //  subject:'',
      //  sender:'',
      //  send_time_after:'',
      //  send_time_before:''
      },
      options_date: [
        {
          value: "Day(s)",
          label: "Day(s)",
        },
        {
          value: "Month(s)",
          label: "Month(s)",
        },
        {
          value: "Year(s)",
          label: "Year(s)",
        },
      ],
      pagination: {
        current: 1,
        pagesize: 6,
        total: 0,
      },
      options: [{
        value: '0',
        label: 'Other'
      }, {
        value: '1',
        label: 'Small Handy'
      }, {
        value: '2',
        label: 'Handysize'
      }, {
        value: '3',
        label: 'Large Handy'
      }, {
        value: '4',
        label: 'Handy Max'
      },
        {
          value: '5',
          label: 'Super Max'
        },{
          value: '6',
          label: 'Ultra Max'
        },
        {
          value: '7',
          label: 'Pana Max'
        },
        {
          value: '8',
          label: 'Kamsar Max'
        },
        {
          value: '9',
          label: 'Post Panamax'
        },
        {
          value: '10',
          label: 'Afra Max'
        },
        {
          value: '11',
          label: 'Capesize'
        },
        {
          value: '12',
          label: 'VLCC'
        },
        {
          value: '13',
          label: 'ULCC'
        }
      ],
      options2: [
        {
          value: 5,
          label: "5",
        },
        {
          value: 10,
          label: "10",
        },
        {
          value: 15,
          label: "15",
        },
        {
          value: 20,
          label: "20",
        },
        {
          value: 25,
          label: '25'
        },
        {
          value: 30,
          label: '30'
        },
        {
          value: 35,
          label: '35'
        },
        {
          value: 40,
          label: '40'
        },
        {
          value: 50,
          label: '50'
        },

      ],
      activeName:'table',
      tableData:[],
      starFontSize:'28px',
      editObj:{}, //点击编辑按钮，传入的编辑对象
      detailObj:{},
      delObj:{},
      deit_dialog :false,
      detail_dialog:false,
      del_dialog:false,
      currentPage1:1,
      intid:'',
      rules:{
        dwtMax: [{ validator: validatedwtMax, type: 'number', trigger: ['blur','change']  }],
        dwtMin: [{ validator: validatedwtMin, type: 'number', trigger: ['blur','change'] }],
        laycanEnd:[{validator: validatelaycanEnd, trigger: ['blur','change']  }],
        laycanStart:[{validator: validatelaycanStart, trigger: ['blur','change']  }],
        durationUp:[{validator: validatedutationUp, trigger: ['blur','change']  }],
        durationDown:[{validator: validatedutationDown, trigger: ['blur','change']  }],
      }
    }
  },
  computed:{
    ...mapGetters(["isMobile","lang","permission","seanamegroup","portnamegroup"]),
  },
  methods:{
     /*jx add 新增功能：按抽取时间排序********/ 
     getCreateTime(time){
      let date = new Date(time)
      return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
    },
    SortTimeMethod({ column, prop, order }) 
    {
      if (order === 'ascending') {
        // 顺序排序逻辑
        this.tableData.sort((a, b) => a[prop] - b[prop]);
      } else if (order === 'descending') {
        // 倒序排序逻辑
        this.tableData.sort((a, b) => b[prop] - a[prop]);
      }
    },
   /**********/
    sendDays(value){
      if(value<0){
        this.emailtctform.sendDays=0}else{
        if(value>365){
          this.emailtctform.sendDays=365;
          this.$message.warning(window.vm.$t('message.onlyyear'))
        }
      }
    },
    goDetails(row){
      this.intid= row.id
      this.$router.push({path:`/tct/addtct/${2}/${this.intid}`,
        query:{
        extract:true,
          email_detail:row.emaildetail_id
        } })
      //2为详情

    },
    go_edit(row){
      //var  editId= [this.editObj]
      this.$router.push({path:`/tct/addtct/${1}/${row.id}`,
          query:{
        extract:true,
            email_detail:row.emaildetail_id
      } })
     /* updateemailbyid(editId).then((res)=>{
        if(res.error_code==200){
          this.$router.push(`/tct/addtct/${1}/${editId}`)
          this.deit_dialog=false
        }
      })*/

    },
    // go_detail(){
    //   var  detailId= [this.detailObj]
    //   searchemailbyid(detailId).then((res)=>{
    //     if(res.error_code == 200){
    //       this.$router.push(`/tct/addtct/${2}/${detailId}`)
    //       this.detail_dialog=false
    //     }
    //   })
    // },
    go_del(){
      deletetctemail(this.delObj).then(response =>{
        if(response.error_code==200){
          this.$message.success(window.vm.$t('message.tct.deletedinfo'))
          this.del_dialog=false
          this.getTctEmail()
        }
      })
    },
  /*  open_Edit(editObj) {
      this.editObj = editObj
      this.deit_dialog = true
    },
    open_Detail(detailObj){
      this.detailObj = detailObj
      this.detail_dialog = true
    },*/
    open_Del(delObj){
      this.delObj = delObj
      this.del_dialog = true
    },
    editTime(time,format){
      return parseTime(time,format)
    },
    //检索邮件抽取tct信息
    getTctEmail(){
      if(this.lang == 'en'){
        this.emailtctform.languageCode = 2
      }else{
        this.emailtctform.languageCode = 1
      }
      // this.emailtctform.deliveryPortId = this.emailtctform.deliveryPortName
      // this.emailtctform.redeliveryPortId = this.emailtctform.redeliveryPortName
      // this.emailtctform.deliverySeaareaId = this.emailtctform.deliverySeaareaName
      // this.emailtctform.redeliverySeaareaId = this.emailtctform.redeliverySeaareaName
      const params = {
        query: this.emailtctform,
        pagination: this.pagination
      }
      console.log("发送数据",params)
      tctemailcard(params).then(response =>{
        if(response.error_code ==200 ){
          this.tableData=response.datas
          this.pagination.total = response.pagination.total
        }
      })
    },
    currentChange(n) {
      this.pagination.current = n
     this.getTctEmail()
    },
    senderEmail(e){
      var value = e.data;
      if(value ==null)
        this.emailtctform.sendername = '';
    },
    typechange(obj){
      if ( this.options[obj].label == 'Other'){
        this.emailtctform.dwtMin=null
        this.emailtctform.dwtMax= null
      }
      else if (this.options[obj].label == 'Small Handy'){
        this.emailtctform.dwtMin= 0
        this.emailtctform.dwtMax= 10000
      }
      else if ( this.options[obj].label == 'Handysize'){
        this.emailtctform.dwtMin= 10000
        this.emailtctform.dwtMax= 40000
      }
      else if(this.options[obj].label == 'Large Handy'){
        this.emailtctform.dwtMin= 30000
        this.emailtctform.dwtMax= 39999
      }
      else if(this.options[obj].label == 'Handy Max'){
        this.emailtctform.dwtMin= 40000
        this.emailtctform.dwtMax= 50000
      }else if(this.options[obj].label == 'Super Max'){
        this.emailtctform.dwtMin= 50000
        this.emailtctform.dwtMax= 59999
      }
      else if(this.options[obj].label == 'Ultra Max'){
        this.emailtctform.dwtMin= 60000
        this.emailtctform.dwtMax= 64999
      }
      else if(this.options[obj].label == 'Pana Max'){
        this.emailtctform.dwtMin= 65000
        this.emailtctform.dwtMax= 79999
      }
      else if(this.options[obj].label == 'Kamsar Max'){
        this.emailtctform.dwtMin= 80000
        this.emailtctform.dwtMax= 89999
      }
      else if(this.options[obj].label == 'Post Panamax'){
        this.emailtctform.dwtMin= 90000
        this.emailtctform.dwtMax= 99999
      }
      else if(this.options[obj].label == 'Afra Max'){
        this.emailtctform.dwtMin= 80000
        this.emailtctform.dwtMax= 110000
      }
      else if(this.options[obj].label == 'Capesize'){
        this.emailtctform.dwtMin= 100000
        this.emailtctform.dwtMax= 450000
      }
      else if(this.options[obj].label == 'VLCC'){
        this.emailtctform.dwtMin= 150000
        this.emailtctform.dwtMax= 320000
      }else if(this.options[obj].label == 'ULCC'){
        this.emailtctform.dwtMin= 320000
        this.emailtctform.dwtMax= 550000
      }
    },
    dwtDownchange(obj){
      if(obj == 0 && this.emailtctform.dwtMax== 10000){
        this.emailtctform.dwt_type = 'Small Handy'
      }else if (obj== 10000 && this.emailtctform.dwtMax== 40000){
        this.emailtctform.dwt_type = 'Handysize'
      } else if( obj== 30000 && this.emailtctform.dwtMax== 39999){
        this.emailtctform.dwt_type = 'Large Handy'
      } else if(obj== 40000 && this.emailtctform.dwtMax== 50000){
        this.emailtctform.dwt_type = 'Handy Max'
      } else if(obj== 50000 && this.emailtctform.dwtMax== 59999){
        this.emailtctform.dwt_type = 'Super Max'
      } else if(obj== 60000 && this.emailtctform.dwtMax== 64999){
        this.emailtctform.dwt_type = 'Ultra Max'
      } else if(obj== 65000 && this.emailtctform.dwtMax== 79999){
        this.emailtctform.dwt_type = 'Pana Max'
      } else if(obj== 80000&& this.emailtctform.dwtMax== 89999){
        this.emailtctform.dwt_type = 'Kamsar Max'
      } else if(obj== 90000&& this.emailtctform.dwtMax== 99999){
        this.emailtctform.dwt_type = 'Post Panamax'
      } else if( obj== 80000&& this.emailtctform.dwtMax== 110000){
        this.emailtctform.dwt_type = 'Afra Max'
      } else if(obj== 100000&& this.emailtctform.dwtMax== 450000){
        this.emailtctform.dwt_type = 'Capesize'
      }else if(obj== 150000&& this.emailtctform.dwtMax== 320000){
        this.emailtctform.dwt_type = 'VLCC'
      }else if( obj== 320000&& this.emailtctform.dwtMax== 550000){
        this.emailtctform.dwt_type = 'ULCC'
      }else{
        this.emailtctform.dwtMin = obj
        this.emailtctform.dwt_type = 'other'
      }
    },
    dwtUpchange(obj){
      if(obj == 10000 && this.emailtctform.dwtMin== 0){
        this.emailtctform.dwt_type = 'Small Handy'
      }else if (obj== 40000 && this.emailtctform.dwtMin== 10000){
        this.emailtctform.dwt_type = 'Handysize'
      } else if( obj== 39999 && this.emailtctform.dwtMin== 30000){
        this.emailtctform.dwt_type = 'Large Handy'
      } else if(obj== 50000 && this.emailtctform.dwtMin== 40000){
        this.emailtctform.dwt_type = 'Handy Max'
      } else if(obj== 59999 && this.emailtctform.dwtMin== 50000){
        this.emailtctform.dwt_type = 'Super Max'
      } else if(obj== 64999 && this.emailtctform.dwtMin== 60000){
        this.emailtctform.dwt_type = 'Ultra Max'
      } else if(obj== 79999 && this.emailtctform.dwtMin== 65000){
        this.emailtctform.dwt_type = 'Pana Max'
      } else if(obj== 89999&& this.emailtctform.dwtMin== 80000){
        this.emailtctform.dwt_type = 'Kamsar Max'
      } else if(obj== 99999&& this.emailtctform.dwtMin== 90000){
        this.emailtctform.dwt_type = 'Post Panamax'
      } else if( obj== 110000&& this.emailtctform.dwtMin== 80000){
        this.emailtctform.dwt_type = 'Afra Max'
      } else if(obj== 450000&& this.emailtctform.dwtMin== 100000){
        this.emailtctform.dwt_type = 'Capesize'
      }else if(obj== 320000&& this.emailtctform.dwtMin== 150000){
        this.emailtctform.dwt_type = 'VLCC'
      }else if( obj== 550000&& this.emailtctform.dwtMin== 320000){
        this.emailtctform.dwt_type = 'ULCC'
      }else{
        this.emailtctform.dwtMax = obj
        this.emailtctform.dwt_type = 'other'
      }
    },
    recipient(e){
      var value = e.data;
      if(value ==null)
        this.emailtctform.recipient = '';
    },
    querySearchDeliverySea(queryString, callback) {
      this.$store.commit("metadata/querySearchSea", queryString);
      callback(this.seanamegroup);
    },
    querySearchRedeliverySea(queryString, callback) {
      this.$store.commit("metadata/querySearchSea", queryString);
      callback(this.seanamegroup);
    },
    querySearchDeliveryPort(queryString, callback) {
      this.$store.commit('metadata/querySearchPort', {"queryString":queryString,"seaAreaId":this.emailtctform.deliverySeaareaId});
      callback(this.portnamegroup);
    },
    querySearchRedeliveryPort(queryString, callback) {
      this.$store.commit('metadata/querySearchPort', {"queryString":queryString,"seaAreaId":this.emailtctform.redeliverySeaareaId});
      callback(this.portnamegroup);
    },
    selectdeliverysea(item) {
      this.emailtctform.deliverySeaareaId = item.id;
    },
    selectredeliverysea(item) {
      this.emailtctform.redeliverySeaareaId = item.id;
    },
    selectdeliveryport(item) {
      this.emailtctform.deliveryPortId = item.id;
      getseaarea(item.seaAreaId).then(res =>{
        this.emailtctform.deliverySeaareaId = res.data.id
        if (this.lang == 'en') {
          this.emailtctform.deliverySeaareaName = res.data.ename
        } else {
          this.emailtctform.deliverySeaareaName = res.data.cname
        }
      })
    },
    selectredeliveryport(item) {
      this.emailtctform.redeliveryPortId = item.id;
      getseaarea(item.seaAreaId).then(res =>{
        this.emailtctform.redeliverySeaareaId = res.data.id
        if (this.lang == 'en') {
          this.emailtctform.redeliverySeaareaName = res.data.ename
        } else {
          this.emailtctform.redeliverySeaareaName = res.data.cname
        }
      })
    },
    //不用点击搜索
    reselectdeliverysea(){
      if(this.seanamegroup.length != 0){
        for(let i=0;i<this.seanamegroup.length;i++) {
          if (this.seanamegroup[i].value == this.emailtctform.deliverySeaareaName) {
            this.selectdeliverysea(this.seanamegroup[i])
            break
          }
        }
      }
    },
    reselectdeliveryport(){
      if(this.portnamegroup.length != 0){
        for(let i=0;i<this.portnamegroup.length;i++) {
          if (this.portnamegroup[i].value == this.emailtctform.deliveryPortName) {
            this.selectdeliveryport(this.portnamegroup[i])
            break
          }
        }
      }
    },
    reselectredeliverysea(){
      if(this.seanamegroup.length != 0){
        for(let i=0;i<this.seanamegroup.length;i++) {
          if (this.seanamegroup[i].value == this.emailtctform.redeliverySeaareaName) {
            this.selectredeliverysea(this.seanamegroup[i])
            break
          }
        }
      }
    },
    reselectredeliveryport(){
      if(this.portnamegroup.length != 0){
        for(let i=0;i<this.portnamegroup.length;i++) {
          if (this.portnamegroup[i].value == this.emailtctform.redeliveryPortName) {
            this.selectredeliveryport(this.portnamegroup[i])
            break
          }
        }
      }
    },
    //更改海域港口置空
    changeredeliverysea(value){
      if(value==''){
        this.emailtctform.redeliverySeaareaId=''
      }else {
        this.emailtctform.redeliveryPortId = ''
        this.emailtctform.redeliveryPortName = ''
        this.reselectredeliverysea()
      }
    },
    changedeliverysea(value){
      if(value==''){
        this.emailtctform.deliverySeaareaId=''
      }else {
        this.emailtctform.deliveryPortId = ''
        this.emailtctform.deliveryPortName = ''
        this.reselectdeliverysea()
      }
    },
    deliverySeaareaId(e){
      var value = e.data;
      if(value ==null)
        this.resetdeliverySeaareaId();
    },
    redeliverySeaareaId(e){
      var value = e.data;
      if(value ==null)
        this.resetredeliverySeaareaId();
    },
    deliveryPortId(e){
      var value = e.data;
      if(value ==null)
        this.resetdeliveryPortId();
    },
    redeliveryPortId(e){
      var value = e.data;
      if(value ==null)
        this.resetredeliveryPortId();
    },
    resetdeliverySeaareaId(){
      this.emailtctform.deliverySeaareaId = "";
    },
    resetredeliverySeaareaId(){
      this.emailtctform.redeliverySeaareaId = "";
    },
    resetdeliveryPortId(){
      this.emailtctform.deliveryPortId = "";
    },
    resetredeliveryPortId(){
      this.emailtctform.redeliveryPortId = "";
    },
    resetForm() {
      this.reload()
    },
  },
  created() {
    this.getTctEmail()
    this.emailtctform.durationType = this.options_date[0].value
    this.buttonpermission = this.permission == null? localStorage.getItem('permission'):this.permission;
    if(this.buttonpermission.includes('51005')){
      this.searchdisabled = false;
    }
    else this.searchdisabled = true;
    if(this.buttonpermission.includes('52007')){
      this.deletedisabled = false;
    }
    else this.deletedisabled = true;
    if(this.buttonpermission.includes('52006')){
      this.editdisabled = false;
    }
    else this.editdisabled = true;
    if(this.buttonpermission.includes('31000')){
      this.matchdisabled = false;
    }
    else this.matchdisabled = true;
  }
}

</script>


<style lang="scss">
.ss-tonnage{
  width:100%;
  heighT:100%;
  //background: pink;
  .search-ton{
    width:100%;
    height:100%;
    .ton-top{
      width:100%;
      //height:26%;
      //background: blue;
      .box-card{
        width:100%;
        height:100%;
        border:none;
        .el-card__body{
          width:100%;
          height:100%;
          padding:5px;
          .el-form{
            width:100%;
            height:100%;
            display: flex;
            .form-left{
              width:80%;
              height:100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              //background: pink;
              .el-form-item{
                margin-bottom: 12px;
                #ship_years{
                  margin-left: 20px;
                }
                .search_btn{
                  width:30%
                }
                .el-button+.el-button{
                  width:30%;
                  margin-left: 30%;
                }
              }
              #col_2{
                color:#fff;
              }
            }
            .form-right{
              flex:1;
              background: pink;
            }
          }
        }
      }
    }
    .result_list{
      margin-top: 1%;
      width:100%;
      height:60%;
      position: relative;
      .el-tabs__header{
        margin:0;
      }
      .el-tabs__content{
        width:100%;
        height:85%;
        //background: brown;
        overflow-y: auto;
        #pane-card{
          width:100%;
          height:100%;
          .list-area{
            width:100%;
            height:100%;
            display: flex;
            flex-wrap: wrap;
            .list-item:hover{
              cursor: pointer;
            }
            .list-item{
              width:30%;
              height:200px;
              margin-bottom: 15px;
              margin-right: 2%;
              // background: #409EeF;
            }
          }

        }
        #pane-table{
          width:100%;
          height:100%;
          .el-table{
            margin-bottom: 30px;
            .actions{
              display: flex;
              justify-content: space-between;
              .actions-box{
                flex:1;
                display: flex;
                justify-content: space-around;
                .el-button--mini{
                  padding:3px 7px;
                }
              }
              .collect:hover{
                cursor: pointer;
              }
              .collect{
                width:28px;
                height:28px;
                margin-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
    .list-footer{
      width:100%;
      display: flex;
      justify-content: center;
      ul,li{
        float: left;
        margin:0px;
        padding: 0px;
      }
      #tiao{
        margin-right: 30px;
      }
    }
  }
  .search-ton-mobile{
    width:100%;
    heighT:100%;
    .ton-top{
      width:100%;
      height:46%;
      //background: blue;
      .box-card{
        width:100%;
        height: 100%;
        .el-card__body{
          width:100%;
          height:100%;
          padding:2px;
          .el-form{
            width:100%;
            height:100%;
            //display: flex;
            #vessel_name{
              margin-top: 1.5%;
            }
            .el-form-item{
              margin-bottom: 10px;
              #ship_years{
                margin-left: 20px;
              }
              .el-button+.el-button{
                margin-left: 25%;
              }
            }
            #col_2{
              color:#fff;
            }
          }
        }
      }
    }
    h2{
      display: flex;
      justify-content: center;
      margin-top: 5%;
    }
    .list-area-mobile{
      width:96%;
      //height:90%;
      margin-left: 3.5%;
      display: flex;
      flex-wrap: wrap;
      //background: #aaa;
      overflow-y: hidden;
      .list-item:hover{
        cursor: pointer;
      }
      .list-item{
        width:100%;
        margin-bottom: 15px;
        margin-right: 5%;
        // background: #409EeF;
      }
    }
    // .result_list{
    //     margin-top: 1%;
    //      width:100%;
    //      height:70%;
    //      .el-tabs__content{
    //          width:100%;
    //          height:85%;
    //          //background: brown;
    //          overflow-y: auto;
    //          #pane-card{
    //              width:100%;
    //              height:100%;
    //               .list-area{
    //                 width:100%;
    //                 height:100%;
    //                 display: flex;
    //                 flex-wrap: wrap;
    //                 .list-item:hover{
    //                     cursor: pointer;
    //                 }
    //                 .list-item{
    //                     width:30%;
    //                     //height:100px;
    //                     margin-bottom: 15px;
    //                     margin-right: 2%;
    //                 // background: #409EeF;
    //                 }
    //              }

    //          }
    //          #pane-table{
    //              width:100%;
    //              height:100%;
    //              .el-table{
    //                 margin-bottom: 30px;
    //                 .actions{
    //                     display: flex;
    //                     justify-content: space-between;
    //                     .collect:hover{
    //                         cursor: pointer;
    //                     }
    //                     .collect{
    //                         width:28px;
    //                         height:28px;
    //                         margin-left: 10px;
    //                         display: flex;
    //                         justify-content: center;
    //                         align-items: center;
    //                     }
    //                 }
    //             }
    //          }
    //      }
    // }
  }
}
</style>
